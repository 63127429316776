<template>
  <div class="Followuplist">
    <div class="title">
      <div class="seach">
        <div class="inputs">
          <el-select
            v-model="from.sale_id"
            clearable
            filterable
            placeholder="所属销售"
          >
            <el-option
              :label="item.fullname"
              :value="item.id"
              :key="item.id"
              v-for="item in salelist"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-input
            v-model="from.fullname"
            placeholder="请输入用户姓名"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="from.mobile"
            placeholder="请输入用户电话"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-select
            v-model="from.follow_type"
            clearable
            placeholder="请选择回访结果"
          >
            <el-option label="无效客户" value="1"></el-option>
            <el-option label="商品质量差" value="2"></el-option>
            <el-option label="商城不好用" value="3"></el-option>
            <el-option label="价格贵" value="4"></el-option>
            <el-option label="客诉慢" value="5"></el-option>
            <el-option label="送货慢" value="6"></el-option>
            <el-option label="采购需求减少" value="7"></el-option>
            <el-option label="待再次回访" value="8"></el-option>
            <el-option label="其他" value="9"></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <div class="divname">最后回访时间：</div>
          <el-date-picker
            @change="payChange"
            v-model="pay_at"
            :time-arrow-control="true"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="—"
            start-placeholder="最后回访时间起"
            end-placeholder="最后回访时间止"
          >
          </el-date-picker>
        </div>

        <el-button
          type="primary"
          icon="el-icon-refresh"
          class="bjsearch"
          @click="Refresh"
        ></el-button>
        <el-button @click="search" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button @click="exportHandle" type="warning" icon="el-icon-upload2"
          >导出</el-button
        >
      </div>
    </div>

    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        ref="multipleTable"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="fullname" align="center" label="客户名称">
        </el-table-column>
        <el-table-column prop="mobile" align="center" label="联系电话">
        </el-table-column>
        <el-table-column prop="type_txt" align="center" label="回访结果">
        </el-table-column>
        <el-table-column prop="follow_time" align="center" label="回访时间">
        </el-table-column>
        <el-table-column prop="op_name" align="center" label="所属城市仓销售">
        </el-table-column>
        <el-table-column prop="remark" align="center" label="备注" :show-overflow-tooltip="true">
        </el-table-column>
      </el-table>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="from.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
            <script>
import { postExportfollow_record } from "@/api/allExport";
import { downLoadFlowBlob } from "@/utils/tools/base";
import { formatDate,getQuarterStartAndEnd } from "@/utils/tools/date.js";
export default {
  name: "Followuplist",
  components: {},
  data() {
    return {
      //列表新建页面初始参数
      loading: false,
      pay_at: getQuarterStartAndEnd(),
      from: {
        start_date: getQuarterStartAndEnd()[0], //	string	开始日期
        end_date: getQuarterStartAndEnd()[1], //	string	结束日期
        logistic_business_id: "", //	number	集配中心ID
        logistics_id: "", //	number	城市仓id
        fullname: "", //	string	用户名
        mobile: "", //	string	手机号
        area_manager: "", //	string	区域经理（暂无）
        sale_name: "", //	string	销售名称
        follow_type: "", //	number	回访结果
        page: 1,
        pageSize: 50,
      },
      from1: {},
      tableData: [],
      currentPage: 1,
      total: 0,
      salelist: [],
    };
  },
  created() {
    if (this.$route.query.mobile) {
      this.from.member_mobile = this.$route.query.mobile;
      this.from.start_date = this.$route.query.start_date;
      this.from.end_date = this.$route.query.end_date;
      this.pay_at = [this.from.start_date, this.from.end_date];
    }
    this.from1 = JSON.parse(JSON.stringify(this.from));
    this.hqlist();
    this.getsaleselectlist();
  },
  methods: {
    getsaleselectlist() {
      this.$api.SalesP.saleselectlist().then((res) => {
        this.salelist = res.data;
      });
    },
    payChange(val) {
      console.log(val,'111')
      if (val) {
        this.from.start_date = val[0];
        this.from.end_date = val[1];
      } else {
        this.pay_at = getQuarterStartAndEnd();
        this.from.start_date =  getQuarterStartAndEnd()[0];
        this.from.end_date =  getQuarterStartAndEnd()[1];
      }
    },
    getList(e) {
      if (e.id) {
        this.hqlist();
      } else {
        this.search();
      }
    },
    //导出
    exportHandle() {
      let params = {
        ...this.from1,
      };
      delete params.page;
      delete params.pageSize;

      postExportfollow_record(params).then((res) => {
        console.log(res);
        downLoadFlowBlob(res, res?.filename);
      });
    },

    gopath(e) {
      this.$router.push({
        path: "/Followuprecords",
        query: {
          id: e.id,
        },
      });
    },
    getList(e) {
      if (e.id) {
        this.hqlist();
      } else {
        this.search();
      }
    },
    hqlist() {
      this.loading = true;
      this.$api.Followup.follow_record_list4(this.from1)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.data;
          this.total = res.data.total;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    search() {
      this.from.page = 1;
      this.from1 = JSON.parse(JSON.stringify(this.from));
      this.currentPage = 1;
      this.hqlist();
    },
    addclick() {
      console.log("新增");
    },

    Refresh() {
      Object.assign(this.$data.from, this.$options.data().from);
      this.from1 = JSON.parse(JSON.stringify(this.from));
      this.pay_at = getQuarterStartAndEnd();
      this.currentPage = 1;
      this.hqlist();
    },
    handleSizeChange(val) {
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.hqlist();
    },
  },
};
</script>
<style lang="css"> .el-tooltip__popper{ max-width:50% } </style>
            <style lang="scss">
            
.Followuplist {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;

  .title {
    background: #ffffff;
    padding: 10px 15px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;

    .seach {
      flex: 1;
      display: flex;
      // justify-content: flex-end;
      align-items: flex-start;
      flex-wrap: wrap;

      .inputs {
        // width: 207px;
        display: flex;
        align-items: center;
        margin-right: 8px;
        margin-bottom: 10px;
      }

      .multiple-select {
        width: 250px;
      }
    }

    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
            
          